	/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/06/2016 15:41
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticons/Flaticon.eot");
  src: url("../fonts/flaticons/Flaticon-1.eot") format("embedded-opentype"),
       url("../fonts/flaticons/Flaticon.woff") format("woff"),
       url("../fonts/flaticons/Flaticon.ttf") format("truetype"),
       url("../fonts/flaticons/Flaticon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticons/Flaticon.svg") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-add:before { content: "\f100"; }
.flaticon-add-1:before { content: "\f101"; }
.flaticon-add-2:before { content: "\f102"; }
.flaticon-add-3:before { content: "\f103"; }
.flaticon-agenda:before { content: "\f104"; }
.flaticon-alarm:before { content: "\f105"; }
.flaticon-alarm-1:before { content: "\f106"; }
.flaticon-alarm-clock:before { content: "\f107"; }
.flaticon-alarm-clock-1:before { content: "\f108"; }
.flaticon-albums:before { content: "\f109"; }
.flaticon-app:before { content: "\f10a"; }
.flaticon-archive:before { content: "\f10b"; }
.flaticon-archive-1:before { content: "\f10c"; }
.flaticon-archive-2:before { content: "\f10d"; }
.flaticon-archive-3:before { content: "\f10e"; }
.flaticon-attachment:before { content: "\f10f"; }
.flaticon-back:before { content: "\f110"; }
.flaticon-battery:before { content: "\f111"; }
.flaticon-battery-1:before { content: "\f112"; }
.flaticon-battery-2:before { content: "\f113"; }
.flaticon-battery-3:before { content: "\f114"; }
.flaticon-battery-4:before { content: "\f115"; }
.flaticon-battery-5:before { content: "\f116"; }
.flaticon-battery-6:before { content: "\f117"; }
.flaticon-battery-7:before { content: "\f118"; }
.flaticon-battery-8:before { content: "\f119"; }
.flaticon-battery-9:before { content: "\f11a"; }
.flaticon-binoculars:before { content: "\f11b"; }
.flaticon-blueprint:before { content: "\f11c"; }
.flaticon-bluetooth:before { content: "\f11d"; }
.flaticon-bluetooth-1:before { content: "\f11e"; }
.flaticon-bookmark:before { content: "\f11f"; }
.flaticon-bookmark-1:before { content: "\f120"; }
.flaticon-briefcase:before { content: "\f121"; }
.flaticon-broken-link:before { content: "\f122"; }
.flaticon-calculator:before { content: "\f123"; }
.flaticon-calculator-1:before { content: "\f124"; }
.flaticon-calendar:before { content: "\f125"; }
.flaticon-calendar-1:before { content: "\f126"; }
.flaticon-calendar-2:before { content: "\f127"; }
.flaticon-calendar-3:before { content: "\f128"; }
.flaticon-calendar-4:before { content: "\f129"; }
.flaticon-calendar-5:before { content: "\f12a"; }
.flaticon-calendar-6:before { content: "\f12b"; }
.flaticon-calendar-7:before { content: "\f12c"; }
.flaticon-checked:before { content: "\f12d"; }
.flaticon-checked-1:before { content: "\f12e"; }
.flaticon-clock:before { content: "\f12f"; }
.flaticon-clock-1:before { content: "\f130"; }
.flaticon-close:before { content: "\f131"; }
.flaticon-cloud:before { content: "\f132"; }
.flaticon-cloud-computing:before { content: "\f133"; }
.flaticon-cloud-computing-1:before { content: "\f134"; }
.flaticon-cloud-computing-2:before { content: "\f135"; }
.flaticon-cloud-computing-3:before { content: "\f136"; }
.flaticon-cloud-computing-4:before { content: "\f137"; }
.flaticon-cloud-computing-5:before { content: "\f138"; }
.flaticon-command:before { content: "\f139"; }
.flaticon-compact-disc:before { content: "\f13a"; }
.flaticon-compact-disc-1:before { content: "\f13b"; }
.flaticon-compact-disc-2:before { content: "\f13c"; }
.flaticon-compass:before { content: "\f13d"; }
.flaticon-compose:before { content: "\f13e"; }
.flaticon-controls:before { content: "\f13f"; }
.flaticon-controls-1:before { content: "\f140"; }
.flaticon-controls-2:before { content: "\f141"; }
.flaticon-controls-3:before { content: "\f142"; }
.flaticon-controls-4:before { content: "\f143"; }
.flaticon-controls-5:before { content: "\f144"; }
.flaticon-controls-6:before { content: "\f145"; }
.flaticon-controls-7:before { content: "\f146"; }
.flaticon-controls-8:before { content: "\f147"; }
.flaticon-controls-9:before { content: "\f148"; }
.flaticon-database:before { content: "\f149"; }
.flaticon-database-1:before { content: "\f14a"; }
.flaticon-database-2:before { content: "\f14b"; }
.flaticon-database-3:before { content: "\f14c"; }
.flaticon-diamond:before { content: "\f14d"; }
.flaticon-diploma:before { content: "\f14e"; }
.flaticon-dislike:before { content: "\f14f"; }
.flaticon-dislike-1:before { content: "\f150"; }
.flaticon-divide:before { content: "\f151"; }
.flaticon-divide-1:before { content: "\f152"; }
.flaticon-division:before { content: "\f153"; }
.flaticon-document:before { content: "\f154"; }
.flaticon-download:before { content: "\f155"; }
.flaticon-edit:before { content: "\f156"; }
.flaticon-edit-1:before { content: "\f157"; }
.flaticon-eject:before { content: "\f158"; }
.flaticon-eject-1:before { content: "\f159"; }
.flaticon-equal:before { content: "\f15a"; }
.flaticon-equal-1:before { content: "\f15b"; }
.flaticon-equal-2:before { content: "\f15c"; }
.flaticon-error:before { content: "\f15d"; }
.flaticon-exit:before { content: "\f15e"; }
.flaticon-exit-1:before { content: "\f15f"; }
.flaticon-exit-2:before { content: "\f160"; }
.flaticon-eyeglasses:before { content: "\f161"; }
.flaticon-fast-forward:before { content: "\f162"; }
.flaticon-fast-forward-1:before { content: "\f163"; }
.flaticon-fax:before { content: "\f164"; }
.flaticon-file:before { content: "\f165"; }
.flaticon-file-1:before { content: "\f166"; }
.flaticon-file-2:before { content: "\f167"; }
.flaticon-film:before { content: "\f168"; }
.flaticon-fingerprint:before { content: "\f169"; }
.flaticon-flag:before { content: "\f16a"; }
.flaticon-flag-1:before { content: "\f16b"; }
.flaticon-flag-2:before { content: "\f16c"; }
.flaticon-flag-3:before { content: "\f16d"; }
.flaticon-flag-4:before { content: "\f16e"; }
.flaticon-focus:before { content: "\f16f"; }
.flaticon-folder:before { content: "\f170"; }
.flaticon-folder-1:before { content: "\f171"; }
.flaticon-folder-10:before { content: "\f172"; }
.flaticon-folder-11:before { content: "\f173"; }
.flaticon-folder-12:before { content: "\f174"; }
.flaticon-folder-13:before { content: "\f175"; }
.flaticon-folder-14:before { content: "\f176"; }
.flaticon-folder-15:before { content: "\f177"; }
.flaticon-folder-16:before { content: "\f178"; }
.flaticon-folder-17:before { content: "\f179"; }
.flaticon-folder-18:before { content: "\f17a"; }
.flaticon-folder-19:before { content: "\f17b"; }
.flaticon-folder-2:before { content: "\f17c"; }
.flaticon-folder-3:before { content: "\f17d"; }
.flaticon-folder-4:before { content: "\f17e"; }
.flaticon-folder-5:before { content: "\f17f"; }
.flaticon-folder-6:before { content: "\f180"; }
.flaticon-folder-7:before { content: "\f181"; }
.flaticon-folder-8:before { content: "\f182"; }
.flaticon-folder-9:before { content: "\f183"; }
.flaticon-forbidden:before { content: "\f184"; }
.flaticon-funnel:before { content: "\f185"; }
.flaticon-garbage:before { content: "\f186"; }
.flaticon-garbage-1:before { content: "\f187"; }
.flaticon-garbage-2:before { content: "\f188"; }
.flaticon-gift:before { content: "\f189"; }
.flaticon-help:before { content: "\f18a"; }
.flaticon-hide:before { content: "\f18b"; }
.flaticon-hold:before { content: "\f18c"; }
.flaticon-home:before { content: "\f18d"; }
.flaticon-home-1:before { content: "\f18e"; }
.flaticon-home-2:before { content: "\f18f"; }
.flaticon-hourglass:before { content: "\f190"; }
.flaticon-hourglass-1:before { content: "\f191"; }
.flaticon-hourglass-2:before { content: "\f192"; }
.flaticon-hourglass-3:before { content: "\f193"; }
.flaticon-house:before { content: "\f194"; }
.flaticon-id-card:before { content: "\f195"; }
.flaticon-id-card-1:before { content: "\f196"; }
.flaticon-id-card-2:before { content: "\f197"; }
.flaticon-id-card-3:before { content: "\f198"; }
.flaticon-id-card-4:before { content: "\f199"; }
.flaticon-id-card-5:before { content: "\f19a"; }
.flaticon-idea:before { content: "\f19b"; }
.flaticon-incoming:before { content: "\f19c"; }
.flaticon-infinity:before { content: "\f19d"; }
.flaticon-info:before { content: "\f19e"; }
.flaticon-internet:before { content: "\f19f"; }
.flaticon-key:before { content: "\f1a0"; }
.flaticon-lamp:before { content: "\f1a1"; }
.flaticon-layers:before { content: "\f1a2"; }
.flaticon-layers-1:before { content: "\f1a3"; }
.flaticon-like:before { content: "\f1a4"; }
.flaticon-like-1:before { content: "\f1a5"; }
.flaticon-like-2:before { content: "\f1a6"; }
.flaticon-link:before { content: "\f1a7"; }
.flaticon-list:before { content: "\f1a8"; }
.flaticon-list-1:before { content: "\f1a9"; }
.flaticon-lock:before { content: "\f1aa"; }
.flaticon-lock-1:before { content: "\f1ab"; }
.flaticon-locked:before { content: "\f1ac"; }
.flaticon-locked-1:before { content: "\f1ad"; }
.flaticon-locked-2:before { content: "\f1ae"; }
.flaticon-locked-3:before { content: "\f1af"; }
.flaticon-locked-4:before { content: "\f1b0"; }
.flaticon-locked-5:before { content: "\f1b1"; }
.flaticon-locked-6:before { content: "\f1b2"; }
.flaticon-login:before { content: "\f1b3"; }
.flaticon-magic-wand:before { content: "\f1b4"; }
.flaticon-magnet:before { content: "\f1b5"; }
.flaticon-magnet-1:before { content: "\f1b6"; }
.flaticon-magnet-2:before { content: "\f1b7"; }
.flaticon-map:before { content: "\f1b8"; }
.flaticon-map-1:before { content: "\f1b9"; }
.flaticon-map-2:before { content: "\f1ba"; }
.flaticon-map-location:before { content: "\f1bb"; }
.flaticon-megaphone:before { content: "\f1bc"; }
.flaticon-megaphone-1:before { content: "\f1bd"; }
.flaticon-menu:before { content: "\f1be"; }
.flaticon-menu-1:before { content: "\f1bf"; }
.flaticon-menu-2:before { content: "\f1c0"; }
.flaticon-menu-3:before { content: "\f1c1"; }
.flaticon-menu-4:before { content: "\f1c2"; }
.flaticon-microphone:before { content: "\f1c3"; }
.flaticon-microphone-1:before { content: "\f1c4"; }
.flaticon-minus:before { content: "\f1c5"; }
.flaticon-minus-1:before { content: "\f1c6"; }
.flaticon-more:before { content: "\f1c7"; }
.flaticon-more-1:before { content: "\f1c8"; }
.flaticon-more-2:before { content: "\f1c9"; }
.flaticon-multiply:before { content: "\f1ca"; }
.flaticon-multiply-1:before { content: "\f1cb"; }
.flaticon-music-player:before { content: "\f1cc"; }
.flaticon-music-player-1:before { content: "\f1cd"; }
.flaticon-music-player-2:before { content: "\f1ce"; }
.flaticon-music-player-3:before { content: "\f1cf"; }
.flaticon-mute:before { content: "\f1d0"; }
.flaticon-muted:before { content: "\f1d1"; }
.flaticon-navigation:before { content: "\f1d2"; }
.flaticon-navigation-1:before { content: "\f1d3"; }
.flaticon-network:before { content: "\f1d4"; }
.flaticon-newspaper:before { content: "\f1d5"; }
.flaticon-next:before { content: "\f1d6"; }
.flaticon-note:before { content: "\f1d7"; }
.flaticon-notebook:before { content: "\f1d8"; }
.flaticon-notebook-1:before { content: "\f1d9"; }
.flaticon-notebook-2:before { content: "\f1da"; }
.flaticon-notebook-3:before { content: "\f1db"; }
.flaticon-notebook-4:before { content: "\f1dc"; }
.flaticon-notebook-5:before { content: "\f1dd"; }
.flaticon-notepad:before { content: "\f1de"; }
.flaticon-notepad-1:before { content: "\f1df"; }
.flaticon-notepad-2:before { content: "\f1e0"; }
.flaticon-notification:before { content: "\f1e1"; }
.flaticon-paper-plane:before { content: "\f1e2"; }
.flaticon-paper-plane-1:before { content: "\f1e3"; }
.flaticon-pause:before { content: "\f1e4"; }
.flaticon-pause-1:before { content: "\f1e5"; }
.flaticon-percent:before { content: "\f1e6"; }
.flaticon-percent-1:before { content: "\f1e7"; }
.flaticon-perspective:before { content: "\f1e8"; }
.flaticon-photo-camera:before { content: "\f1e9"; }
.flaticon-photo-camera-1:before { content: "\f1ea"; }
.flaticon-photos:before { content: "\f1eb"; }
.flaticon-picture:before { content: "\f1ec"; }
.flaticon-picture-1:before { content: "\f1ed"; }
.flaticon-picture-2:before { content: "\f1ee"; }
.flaticon-pin:before { content: "\f1ef"; }
.flaticon-placeholder:before { content: "\f1f0"; }
.flaticon-placeholder-1:before { content: "\f1f1"; }
.flaticon-placeholder-2:before { content: "\f1f2"; }
.flaticon-placeholder-3:before { content: "\f1f3"; }
.flaticon-placeholders:before { content: "\f1f4"; }
.flaticon-play-button:before { content: "\f1f5"; }
.flaticon-play-button-1:before { content: "\f1f6"; }
.flaticon-plus:before { content: "\f1f7"; }
.flaticon-power:before { content: "\f1f8"; }
.flaticon-previous:before { content: "\f1f9"; }
.flaticon-price-tag:before { content: "\f1fa"; }
.flaticon-print:before { content: "\f1fb"; }
.flaticon-push-pin:before { content: "\f1fc"; }
.flaticon-radar:before { content: "\f1fd"; }
.flaticon-reading:before { content: "\f1fe"; }
.flaticon-record:before { content: "\f1ff"; }
.flaticon-repeat:before { content: "\f200"; }
.flaticon-repeat-1:before { content: "\f201"; }
.flaticon-restart:before { content: "\f202"; }
.flaticon-resume:before { content: "\f203"; }
.flaticon-rewind:before { content: "\f204"; }
.flaticon-rewind-1:before { content: "\f205"; }
.flaticon-route:before { content: "\f206"; }
.flaticon-save:before { content: "\f207"; }
.flaticon-search:before { content: "\f208"; }
.flaticon-search-1:before { content: "\f209"; }
.flaticon-send:before { content: "\f20a"; }
.flaticon-server:before { content: "\f20b"; }
.flaticon-server-1:before { content: "\f20c"; }
.flaticon-server-2:before { content: "\f20d"; }
.flaticon-server-3:before { content: "\f20e"; }
.flaticon-settings:before { content: "\f20f"; }
.flaticon-settings-1:before { content: "\f210"; }
.flaticon-settings-2:before { content: "\f211"; }
.flaticon-settings-3:before { content: "\f212"; }
.flaticon-settings-4:before { content: "\f213"; }
.flaticon-settings-5:before { content: "\f214"; }
.flaticon-settings-6:before { content: "\f215"; }
.flaticon-settings-7:before { content: "\f216"; }
.flaticon-settings-8:before { content: "\f217"; }
.flaticon-settings-9:before { content: "\f218"; }
.flaticon-share:before { content: "\f219"; }
.flaticon-share-1:before { content: "\f21a"; }
.flaticon-share-2:before { content: "\f21b"; }
.flaticon-shuffle:before { content: "\f21c"; }
.flaticon-shuffle-1:before { content: "\f21d"; }
.flaticon-shutdown:before { content: "\f21e"; }
.flaticon-sign:before { content: "\f21f"; }
.flaticon-sign-1:before { content: "\f220"; }
.flaticon-skip:before { content: "\f221"; }
.flaticon-smartphone:before { content: "\f222"; }
.flaticon-smartphone-1:before { content: "\f223"; }
.flaticon-smartphone-10:before { content: "\f224"; }
.flaticon-smartphone-11:before { content: "\f225"; }
.flaticon-smartphone-2:before { content: "\f226"; }
.flaticon-smartphone-3:before { content: "\f227"; }
.flaticon-smartphone-4:before { content: "\f228"; }
.flaticon-smartphone-5:before { content: "\f229"; }
.flaticon-smartphone-6:before { content: "\f22a"; }
.flaticon-smartphone-7:before { content: "\f22b"; }
.flaticon-smartphone-8:before { content: "\f22c"; }
.flaticon-smartphone-9:before { content: "\f22d"; }
.flaticon-speaker:before { content: "\f22e"; }
.flaticon-speaker-1:before { content: "\f22f"; }
.flaticon-speaker-2:before { content: "\f230"; }
.flaticon-speaker-3:before { content: "\f231"; }
.flaticon-speaker-4:before { content: "\f232"; }
.flaticon-speaker-5:before { content: "\f233"; }
.flaticon-speaker-6:before { content: "\f234"; }
.flaticon-speaker-7:before { content: "\f235"; }
.flaticon-speaker-8:before { content: "\f236"; }
.flaticon-spotlight:before { content: "\f237"; }
.flaticon-star:before { content: "\f238"; }
.flaticon-star-1:before { content: "\f239"; }
.flaticon-stop:before { content: "\f23a"; }
.flaticon-stop-1:before { content: "\f23b"; }
.flaticon-stopwatch:before { content: "\f23c"; }
.flaticon-stopwatch-1:before { content: "\f23d"; }
.flaticon-stopwatch-2:before { content: "\f23e"; }
.flaticon-stopwatch-3:before { content: "\f23f"; }
.flaticon-stopwatch-4:before { content: "\f240"; }
.flaticon-street:before { content: "\f241"; }
.flaticon-street-1:before { content: "\f242"; }
.flaticon-substract:before { content: "\f243"; }
.flaticon-substract-1:before { content: "\f244"; }
.flaticon-success:before { content: "\f245"; }
.flaticon-switch:before { content: "\f246"; }
.flaticon-switch-1:before { content: "\f247"; }
.flaticon-switch-2:before { content: "\f248"; }
.flaticon-switch-3:before { content: "\f249"; }
.flaticon-switch-4:before { content: "\f24a"; }
.flaticon-switch-5:before { content: "\f24b"; }
.flaticon-switch-6:before { content: "\f24c"; }
.flaticon-switch-7:before { content: "\f24d"; }
.flaticon-tabs:before { content: "\f24e"; }
.flaticon-tabs-1:before { content: "\f24f"; }
.flaticon-target:before { content: "\f250"; }
.flaticon-television:before { content: "\f251"; }
.flaticon-television-1:before { content: "\f252"; }
.flaticon-time:before { content: "\f253"; }
.flaticon-trash:before { content: "\f254"; }
.flaticon-umbrella:before { content: "\f255"; }
.flaticon-unlink:before { content: "\f256"; }
.flaticon-unlocked:before { content: "\f257"; }
.flaticon-unlocked-1:before { content: "\f258"; }
.flaticon-unlocked-2:before { content: "\f259"; }
.flaticon-upload:before { content: "\f25a"; }
.flaticon-user:before { content: "\f25b"; }
.flaticon-user-1:before { content: "\f25c"; }
.flaticon-user-2:before { content: "\f25d"; }
.flaticon-user-3:before { content: "\f25e"; }
.flaticon-user-4:before { content: "\f25f"; }
.flaticon-user-5:before { content: "\f260"; }
.flaticon-user-6:before { content: "\f261"; }
.flaticon-user-7:before { content: "\f262"; }
.flaticon-users:before { content: "\f263"; }
.flaticon-users-1:before { content: "\f264"; }
.flaticon-video-camera:before { content: "\f265"; }
.flaticon-video-camera-1:before { content: "\f266"; }
.flaticon-video-player:before { content: "\f267"; }
.flaticon-video-player-1:before { content: "\f268"; }
.flaticon-video-player-2:before { content: "\f269"; }
.flaticon-view:before { content: "\f26a"; }
.flaticon-view-1:before { content: "\f26b"; }
.flaticon-view-2:before { content: "\f26c"; }
.flaticon-volume-control:before { content: "\f26d"; }
.flaticon-volume-control-1:before { content: "\f26e"; }
.flaticon-warning:before { content: "\f26f"; }
.flaticon-wifi:before { content: "\f270"; }
.flaticon-wifi-1:before { content: "\f271"; }
.flaticon-windows:before { content: "\f272"; }
.flaticon-windows-1:before { content: "\f273"; }
.flaticon-windows-2:before { content: "\f274"; }
.flaticon-windows-3:before { content: "\f275"; }
.flaticon-windows-4:before { content: "\f276"; }
.flaticon-wireless-internet:before { content: "\f277"; }
.flaticon-worldwide:before { content: "\f278"; }
.flaticon-worldwide-1:before { content: "\f279"; }
.flaticon-zoom-in:before { content: "\f27a"; }
.flaticon-zoom-out:before { content: "\f27b"; }
.gemologocal_education .nav-tabs .nav-item.show .nav-link,
.gemologocal_education .nav-tabs .nav-link.active {
  color: #fff;
  background-color: transparent;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: 700;
  /*font-family: vanitas, sans-serif;*/
  border: none;
}

.gemologocal_education .nav-tabs {
  border-bottom: none;
}

.gemologocal_education .nav-tabs .nav-item {
  margin-bottom: 0px;
}

.gemologocal_education .nav-tabs .nav-link {
  border-radius: 0;
  border: none;
  padding: 9px 26px;
  color: #1f2d3d;
  font-weight: 700;
  letter-spacing: 1px;
  border-bottom: 2px transparent solid;
  /*font-family: vanitas, sans-serif;*/
}

.gemologocal_education .nav-tabs .nav-link:hover {
  color: #7450fe;
}

.gemologocal_education .nav-tabs .nav-link.active {
  color: #7450fe;
  border-bottom: 2px #7450fe solid;
}

.tab-content {
  padding: 25px 1%;
}

.tab-content ul li {
  list-style-position: inside;
}

/*tab-css*/

/*Contact_your_local_IGI*/
.Contact_your_local_IGI {
  /* background: url(../images/submit-your-gem-bg.html) no-repeat center center; */
  padding: 0;
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  background-attachment: fixed;
}

.Contact_your_local_IGI .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  min-height: auto;
  color: #fff;
  padding: 160px 0;
  text-align: center;
}

/*Contact_your_local_IGI*/

/*Retail_Support_Brochure*/
.Retail_Support_Brochure {
  /* background: url(../images/retail-support-brochure-home.html) no-repeat center center; */
  padding: 0;
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  background-attachment: fixed;
}

.Retail_Support_Brochure .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  min-height: auto;
  color: #fff;
  padding: 160px 0;
  text-align: center;
}

.menu-container {
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: fixed;
  left: -60px;
  top: 207px;
  z-index: 99;
  width: 50px;
  border-radius: 0 35px 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  transition: all 0.5s ease;
  transform: translateX(36px);
  left: -40px;
  /* width: 40px; */
  border-radius: 0 20px 20px 0;
}
.social_media_text {
  transform: rotate(-90deg);
  position: absolute;
  width: 103px;
  left: -60px;
  top: 75px;
  font-weight: 700;
}
/* .menu-container {
    transform: translateX(36px);
    left: -35px;
    width: 40px;
    border-radius: 0 20px 20px 0;
  } */
.menu-container.collapse-icon {
  opacity: 0;
  display: none;
}
/* .menu-container:hover .menu-item {
    opacity: 1;
  } */
/* .menu-container:hover .menu-item a {
    color: #901ec1 !important;
    margin-left: 43px;
    width: 26px;
  } */
/* .menu-container:hover .menu-item .fa {
    font-size: 25px;
    padding-left: 25px;
    color: #901ec1;
  } */
/* .menu-container:hover .menu-item:hover {
    cursor: pointer;
  } */
/* .menu-container:hover .menu-item:hover .menu-item-text {
    transform: translateX(147px);
  } */
.menu-container .collapse-icon {
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 100%;
  color: #901ec1;
}
.menu-container .menu-item {
  color: #901ec1;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  padding: 5px 5px;
  /* margin-right: 40px; */
}
.menu-container .menu-item .menu-item-text {
  display: flex;
  position: absolute;
  top: 7px;
  background: #901ec1;
  background: -moz-linear-gradient(to right, #901ec1 2%, #7450fe 82%);
  background: -webkit-linear-gradient(to right, #901ec1 2%, #7450fe 82%);
  background: linear-gradient(to right, #901ec1 2%, #7450fe 82%);
  background: linear-gradient(to right, #901ec1 2%, #7450fe 82%);
  height: 36px;
  width: 98px;
  font-weight: 700;
  color: white;
  right: 30px;
  border-radius: 0px 22px 22px 0px;
  z-index: 0;
  padding: 0px 5px;
  transition: all 0.5s ease 0s;
  align-items: center;
  justify-content: center;
  border-left: 5px solid rgb(35 247 152);
  text-align: center;
  font-size: 0.75rem;
  margin-right: 20px;
}

.uuu {
  border: 1px solid #ccc;
  height: auto;
  padding: 0px;
  color: #000;
  text-indent: auto;
  background-image: auto;
  border-radius: 3px;
  box-shadow: auto;
  font-weight: auto;
  line-height: 35px;
  padding-right: 0px;
  padding-left: 0px;
  font-size: auto;

}
.slick-slide img {
  display: initial !important;
}
.css-ahj2mt-MuiTypography-root {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.footer-title:before {
  position: absolute;
  content: "";
  top: 30%;
  width: 10px;
  height: 10px;
  display: none !important;
  left: 0;
  box-shadow: 3px 3px 0px 0px rgb(196 171 230);
  background-color: #fff;
}

body {
  /* padding: 111px 0 0 0; */
  margin: 0;
  font-size: 13px;
  color: #333;
  font-family: "Poppins", sans-serif !important;
  /* background: #cfcfcf */
}

.head {
  width: 100%;
  height: 112px;
  /* background: #ccc !important; */
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 10%);
  z-index: 20;
}

.logo1 {
  height: 85px;
  transition: all 0.5s ease;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  /* background: white; */
}

.logo2 {
  width: 150px;
}

.logo2pos {
  margin-top: 0px;
  text-align: center;
}

.border-none {
  border: none !important;
}

.colcard {
  border-right: 1px solid #b3b3b3;
}

.colposition {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 30px;
}

.tf {
  font-size: 14px;
  font-weight: 500;
}

.imgstyle {
  width: 100%;
  /* height: 500px; */
}

.gemologocal_education .nav-tabs {
  border-bottom: 1px solid #dee2e6 !important;
}

.tabstyle {
  color: #901ec1 !important;
  border-bottom: 2px #901ec1 solid;
  font-size: 12px !important;
  padding: 18px 35px !important;
  font-size: 12px !important;
}

.pdf-view-btn {
  background: #901ec1;
  border-color: #901ec1;
  margin: 0 0 10px 0;
  color: #fff;
  outline: none;
}

.pdf-view-btn:hover {
  background: #7ea2af;
  border-color: #7ea2af;
  margin: 0 0 10px 0;
  color: #fff;
}

.cros {
  font-size: 15px;
  background: red;
  padding: 10px;
  color: white;
}

.pdf-download-btn .theme_btn {
  border: 0;
  border-radius: 0px;
  background-color: transparent;
  color: #333 !important;
  padding: 0;
  text-transform: capitalize;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  transition: 0.5s;
}

.pdf-download-btn a {
  font-weight: bold;
  margin-right: 18px;
  color: #333;
  font-size: 16px;
  display: inline-block;
  margin-bottom: 7px;
  text-decoration: none;
}

.shareicon {
  color: #901ec1;
  font-size: 17px;
}

footer {
  /* padding: 80px 5px 30px; */
  background-color: #1a1a1a;
}

.footer_logo {
  margin-bottom: 30px;
  width: 110px;
}

.footer_slogan span {
  /* max-width: 46vw; */
  display: inline-block;
}

.footer_slogan {
  display: block;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
  padding-bottom: 20px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
  /* font-family: vanitas, sans-serif; */
  color: #fff;
  font-size: 22px;
  line-height: 34px;
}

.sloganspan {
  /* max-width: 46vw; */
  display: inline-block;
}

.sidemenu {
  font-size: 25px;
  padding: 3px;
  border: 1px solid #901ec1;
  color: #901ec1;
  border-radius: 3px;
  margin-left: 125px;
  margin-top: 20%;
}

.menuactivity {
  display: none;
}

@media screen and (max-width: 600px) {
  .arowhide {
    display: inline-block !important;
    /* padding-bottom:300px */
  }

  .btnstyles {
    background: #e3e0e0;
    padding: 10px;
    width: 100%;
    text-align: center;
    border-radius: 25px;
  }
  /* admin..... */

  .cpad {
    padding: 3px !important;
  }
  .gridstyle {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .mobload {
    padding: 115px !important;
  }
  .navbar-expand-lg .navbar-collapse {
    /* display: -ms-flexbox !important; */
    /* display: flex !important; */
    /* -ms-flex-preferred-size: auto; */
    flex-basis: auto;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 11px;
  }
}

@media screen and (max-width: 800px) {
  .arowhide {
    display: inline-block !important;
    /* padding-bottom:300px */
  }

  .btnstyles {
    background: #e3e0e0;
    padding: 10px;
    width: 100%;
    text-align: center;
    border-radius: 25px;
  }
  /* admin..... */

  .cpad {
    padding: 3px !important;
  }
  .gridstyle {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .mobload {
    padding: 115px !important;
  }
  .navbar-expand-lg .navbar-collapse {
    /* display: -ms-flexbox !important; */
    /* display: flex !important; */
    /* -ms-flex-preferred-size: auto; */
    flex-basis: auto;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 11px;
  }
}
.navbar-light .navbar-nav .nav-link {
  color: #222222;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: 500;
  /* font-family: 'Oswald', sans-serif; */
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  /* font-size: 13.4px; */
  font-size: 11px;
}

@media screen and (max-width: 200px) {
  .arowhide {
    display: inline-block !important;
    /* padding-bottom:300px */
  }

  .btnstyles {
    background: #e3e0e0;
    padding: 10px;
    width: 100%;
    text-align: center;
    border-radius: 25px;
  }
  /* admin..... */

  .cpad {
    padding: 3px !important;
  }
  .gridstyle {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .mobload {
    padding: 115px !important;
  }
  .navbar-expand-lg .navbar-collapse {
    /* display: -ms-flexbox !important; */
    /* display: flex !important; */
    /* -ms-flex-preferred-size: auto; */
    flex-basis: auto;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 11px;
  }
}
.navbar-light .navbar-nav .nav-link {
  color: #222222;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: 500;
  /* font-family: 'Oswald', sans-serif; */
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  /* font-size: 13.4px; */
  font-size: 11px;
}

.navbar-collapse {
  /* -ms-flex-preferred-size: 100%; */
  /* flex-basis: 100%; */
  /* -ms-flex-positive: 1; */
  flex-grow: 1;
  /* -ms-flex-align: center; */
  align-items: center;
}

.gemologocal_education .nav-tabs .nav-link {
  padding: 9px 12px !important;
  font-size: 12px !important;
}

@media screen and (max-width: 1000px) {
  .arowhide {
    /* display: inline-block !important; */

    /* padding-bottom:300px */
  }

  .btnstyles {
    background: #e3e0e0;
    padding: 10px;
    width: 100%;
    text-align: center;
    border-radius: 25px;
  }
  /* admin..... */

  .cpad {
    padding: 3px !important;
  }
  .gridstyle {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .mobload {
    padding: 115px !important;
  }
  .navbar-expand-lg .navbar-collapse {
    /* display: -ms-flexbox !important; */
    /* display: flex !important; */
    /* -ms-flex-preferred-size: auto; */
    flex-basis: auto;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 11px;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #222222;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: 500;
  /* font-family: 'Oswald', sans-serif; */
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  /* font-size: 13.4px; */
  font-size: 11px;
}

.navbar-collapse {
  /* -ms-flex-preferred-size: 100%; */
  /* flex-basis: 100%; */
  /* -ms-flex-positive: 1; */
  flex-grow: 1;
  /* -ms-flex-align: center; */
  align-items: center;
}

.gemologocal_education .nav-tabs .nav-link {
  padding: 9px 12px !important;
  font-size: 12px !important;
}

.gemologocal_education .nav-tabs {
  border-bottom: 1px solid #dee2e6 !important;
}

.nav {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.gemologocal_education .nav-tabs .nav-item {
  margin-bottom: 0px;
}
.entry {
  margin-top: -114px;
  height: 660px !important;
  background: rgb(104, 104, 104) !important;
  /* background: none; */
  padding: 100px;
}

.imgmar {
  margin-top: 100px !important;
}
.textfont {
  font-size: 13px;
  font-weight: 600;
}
.textfont1 {
  font-size: 14px !important;
  /* font-weight: 600; */
}
.cardmrg {
  margin-top: -45px;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.loadingstyle {
  /* text-align: center;
    width: 100%;
    padding: 100px 0px 0px0px;
    background: #787878;
    margin-top: -114px;
    padding: 192px; */
  text-align: center;
  width: 100%;
  padding: 100px 0px 0px0px;
  /* background: #787878; */
  margin-top: -114px;
  padding: 192px;
  position: absolute;
  z-index: 99999999999999999999;
  opacity: 0.9;
  background: rgba(0, 0, 0, 0.7);
  height: 185%;
  position: fixed;
}
/* Admin........ */

a {
  text-decoration: none;
}

.header-righticon {
  justify-content: space-between;
  text-align: center;
  display: flex;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-left {
  display: flex;
}

.header-lefttitle {
  display: inline;
  padding-top: 14px;
}

.Drawer-row {
  display: flex;
  margin: 0px;
  width: 100%;
  /* border-top: 1px solid #f3e1b0; */
  /* border-top: 1px solid #e3c677; */
  border-top: 1px solid #c1c1c1;
}

a:hover .Drawer-row {
  background: #a765c4;
  color: #901ec1;
  min-width: 100%;
}

a:hover .Drawertitle {
  /* color: #7e600d; */
  color: #fff;
}

/* a:hover .Drawericon {
    color: white;
  }
  li.active{
    background: rgb(194, 46, 4) !important;
  }
  li.active .Drawertitle{
    color: rgb(255, 255, 255) !important;
  }
  li.active .Drawer-row{
    color: rgb(255, 255, 255) !important;
  }
  li.active .Drawericon{
    color: rgb(255, 255, 255) !important;
  } */

.Drawerlist {
  padding-top: 30px;
}

a:hover .Drawericon {
  /* color: #7e600d; */
  color: #fff;
}

.MuiIconButton-edgeStart {
  margin-left: -0px;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-edgeStart {
  margin-left: 0px;
}

.makeStyles-toolbar-7 {
  height: 50px;
}

main.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0px solid rgba(0, 0, 0, 0.12) !important;
}

::-webkit-scrollbar {
  display: none;
}

.Drawerlist {
  display: inline-block;
  width: 100%;
  padding: 30px 0px 14px 0px;
}

.Drawerrow {
  display: flex;
  padding: 4px;
  text-align: center;
  width: 100%;
}

.Drawericon {
  /* color: black; */
  color: #901ec1;
  text-align: center;
}

.Drawertitle {
  color: black;
  font-size: 14px;
  padding: 10px 10px 10px;
  /* font-family: Poppins, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue; */
  font-weight: 400;
}

.Drawericon {
  padding: 8px 20px 10px;
  font-size: 14px;
}

.overflow-hidden {
  overflow: hidden !important;
}

.Drawer-a {
  padding: 0px;
  text-decoration: none !important;
}

ul.Drawerlist li {
  margin: -1px;
  margin-left: 5px;
}

.cardstyle {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 10px !important;
  border: none;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0 0.46875rem 2.1875rem rgb(90 97 105 / 10%),
    0 0.9375rem 1.40625rem rgb(90 97 105 / 10%),
    0 0.25rem 0.53125rem rgb(90 97 105 / 12%),
    0 0.125rem 0.1875rem rgb(90 97 105 / 10%);
}
.cardheader {
  border-bottom-color: #f9f9f9;
  line-height: 30px;
  -ms-grid-row-align: center;
  align-self: center;
  width: 100%;
  padding: 10px 25px;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
}
.cardh4 {
  font-size: 15px;
  line-height: 28px;
  padding-right: 10px;
  margin-bottom: 0;
  color: #212529;
}

.cview {
  border: none;
  border-bottom: 2px solid #bfbdb9;
  border-radius: 1px;
}

.cardbox {
  border: none;
  box-shadow: 0 6px 12px rgb(140 152 164);
}

.profilelogo {
  width: 165px;
  /* margin-top: 100px; */
  /* margin-left: 470px; */
  /* border-radius: 85px;
    border: 3px solid #d18663;
    background: white;    */
}

.profilefield {
  /* border: 2px solid #c3c1c1;
    border-radius: 13px;
    height: 50px; */
  border: 1px solid #7e3ee8;
  border-radius: 10px;
  height: 40px;
}

.ptext {
  margin-top: 12px;
  margin-left: 20px;
}

.pcard {
  text-decoration: none;
  color: #7e3ee8;
}

.limg {
  width: 200px;
  height: 100px;
}
.addbtn {
  float: right;
  margin-top: 7px !important;
  /* margin-right: -35px !important; */
}
.modalpad {
  padding: 70px 0px 0px 0px;
}
.modalbod {
  padding: 20px !important;
}
.dascard {
  position: relative;
  color: #000000;
  padding: 15px;
  border-radius: 3px;
  overflow: hidden;
}
.export {
  padding: 5px 10px !important;
}
.filter {
  padding: 5px 10px !important;
  margin-top: 0px !important;
}
